.tabitem {
  min-width: 250px;
  &:active {
    outline: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  & .title {
    width: 100%;
    overflow: hidden;
  }

  & .value {
    & .value {
      font-size: 40px;
    }
  }
}

.p-tab__kpi {
  min-width: unset;
  min-height: unset;
}

.p-tab__inline-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  grid-gap: 30px;
}

.p-tab__table__container {
  overflow-x: auto;
  overflow-y: hidden;
  width: fit-content;
}

.p-tab__table {
  border-collapse: collapse;
  table-layout: fixed;

  white-space: pre-line !important;

  & .table_cell > .tag {
    min-width: 70px;
  }
}
