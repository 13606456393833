.bg-tag {
  @include transition(all 0.2s ease-in-out);
  min-width: 50px;
  padding: 2px 5px;
  border-radius: 2px;
}

.bg-tag__padding__icon {
  padding: 2px 5px 2px 2px;
}

.bg-tag__less {
  color: $secondary-0;
  background: $secondary-6;
}

.bg-tag__more {
  color: $green-0;
  background: $green-7;
}

.bg-tag__none {
  background: $monochromatic-7;
  color: $monochromatic-2;
}

.bg-tag__gray {
  background: $monochromatic-4;
  color: $monochromatic-2;
}

.bg-tag__primary {
  color: $primary-0;
  background-color: $blue-8;
}

.bg-tag__primary-1 {
  color: $primary-0;
  background-color: $first-extended-6;
}

.bg-tag__yellow {
  background-color: $second-extended-5;
  color: $second-extended-6;
}

.bp4-tag-remove {
  color: $primary-0;
  opacity: 0.5;

  &:hover,
  &:active {
    color: $primary-0;
    opacity: 1;
  }
}
