$orange0: #a66321;
$orange1: #bf7326;
$orange3: #f29d49;
$black: #000;
$white: #fff;
$primary0: #282d61;
$primary1: #3b4785;
$primary2: #4a599a;
$primary3: #6371a8;
$primary4: #a2abcc;
$primary5: #e9ebf2;
$secondary0: #f26233;
$secondary1: #b34419;
$secondary2: #d9552b;
$secondary3: #e75c2f;
$secondary4: #f3774f;
$secondary5: #f7ae97;
$secondary6: #f8eae8;
$monochromatic0: #212121;
$monochromatic1: #3e3e3e;
$monochromatic2: #7a7a7a;
$monochromatic3: #ababab;
$monochromatic4: #cacaca;
$monochromatic5: #e0e0e0;
$monochromatic6: #ebebeb;
$monochromatic7: #f6f6f6;
$green0: #26925e;
$green1: #2ba664;
$green2: #31bb67;
$green3: #36d068;
$green4: #68dc8e;
$green5: #9be8b4;
$green6: #c3f1d2;
$green7: #d7f6e1;
$green8: #ebfaf0;
$green9: #f5fdf7;
$blue0: #1856ad;
$blue1: #1b6dc6;
$blue2: #1f88de;
$blue3: #3491e1;
$blue4: #22a5f7;
$blue5: #59bcf9;
$blue6: #91d2fb;
$blue7: #bde4fd;
$blue8: #d3edfd;
$blue9: #e9f6fe;
$blue10: #f4fbff;
$firstExtended0: #161a42;
$firstExtended1: #343a67;
$firstExtended2: #525c8f;
$firstExtended3: #7281b8;
$firstExtended4: #abb5db;
$firstExtended5: #dadff2;
$firstExtended6: #e6e7f5;
$secondExtended0: #437899;
$secondExtended1: #3daaad;
$secondExtended2: #5db8cf;
$secondExtended3: #79c6f2;
$secondExtended4: #89a0e9;
$secondExtended5: #9179e0;
$secondExtended6: #d375a8;
$secondExtended7: #ff7070;
$secondExtended8: #ffa19d;
