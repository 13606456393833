.widget {
  margin-bottom: 0 !important;
}

.widget-filter-loading {
  opacity: 0.3;
}

.widget-filter-loading-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  & .bp4-spinner-head {
    stroke: $primary-0;
  }
}
