.widget-loading {
  background: $monochromatic-7
    linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 8px;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
