.btn-primary__outline {
  @include transition(all 0.2s ease-in-out);
  min-width: unset !important;
  min-height: unset !important;
  border-radius: 8px;
  font-family: 'GT-Eesti-Pro-Display', sans-serif;
  outline: none !important;
  cursor: pointer;
  background-color: transparent !important;
  background-image: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;

  border: 2px solid $primary-0;
  color: $primary-0 !important;

  &:hover {
    background-color: $primary-5 !important;
    box-shadow: 0 2px 4px 0 #cacaca !important;
  }

  &:active {
    background-color: $primary-4 !important;
    box-shadow: none !important;
  }
  & > span {
    color: $primary-0 !important;
  }
}

.link__mono3 {
  @include transition(color 0.2 ease-in);
  color: $monochromatic-3;

  &:hover {
    text-decoration: none !important;
    color: $primary-3;
  }
}

.collapse__button {
  position: absolute;
  top: 40px;
  right: -15px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: white !important;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
}

@media only screen and (max-width: 1024px) {
  .collapse__button {
    visibility: unset;
    opacity: 1;
  }
}

.edit-entity-btn {
  box-shadow: none !important;
  background-color: white !important;
}
.lock-icon-wrapper {
  display: inline-block;
  transform: translateY(6px);
}

.edit-entity-btn span {
  width: auto;
  overflow: visible;
}

.venus-widget-wrapper {
  padding-bottom: 60px;

  &:last-child {
    padding-bottom: 6px;
  }

  &:hover {
    & .edit-entity-btn {
      opacity: 1 !important;
    }

    & .widget-settings-btn {
      opacity: 1 !important;
    }
  }
}

.save-entity {
  z-index: 100 !important;
  width: 30px;
  height: 30px;

  background-clip: initial !important;
  background-color: initial !important;
  box-shadow: none !important;

  &:hover {
    background-clip: initial !important;
    background-color: initial !important;
    box-shadow: none !important;
  }

  & > svg {
    width: 27px;
    height: 27px;
  }
}

.u-bottom-0 {
  bottom: 0 !important;
}

.u-bottom-5 {
  bottom: 5px !important;
}

.u-bottom-70 {
  bottom: 70px !important;
}

.export-dropdown {
  .bp4-overlay-content {
    z-index: 2000;
  }
}
