@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    color: #212121;
  }
  .unreset {
    p {
      margin: 1em 0;
    }

    blockquote,
    figure {
      margin: 1em 40px;
    }

    q {
      quotes: '“' '”' '‘' '’';

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }

    hr {
      border: 1px inset;
      box-sizing: border-box;
      margin: 0.5em auto;
    }

    h1 {
      font-size: 2em;
      font-weight: bold;
      margin: 0.67em 0;
    }

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      margin: 0.83em 0;
    }

    h3 {
      font-size: 1.17em;
      font-weight: bold;
      margin: 1em 0;
    }

    h4 {
      font-size: 1em;
      font-weight: bold;
      margin: 1.33em 0;
    }

    h5 {
      font-size: 0.83em;
      font-weight: bold;
      margin: 1.67em 0;
    }

    h6 {
      font-size: 0.67em;
      font-weight: bold;
      margin: 2.33em 0;
    }

    article,
    aside,
    nav,
    section {
      h1 {
        font-size: 1.5em;
        font-weight: bold;
        margin: 0.83em 0;
      }

      article,
      aside,
      nav,
      section {
        h1 {
          font-size: 1.17em;
          font-weight: bold;
          margin: 1em 0;
        }

        article,
        aside,
        nav,
        section {
          h1 {
            font-size: 1em;
            font-weight: bold;
            margin: 1.33em 0;
          }

          article,
          aside,
          nav,
          section {
            h1 {
              font-size: 0.83em;
              font-weight: bold;
              margin: 1.67em 0;
            }

            article,
            aside,
            nav,
            section {
              h1 {
                font-size: 0.67em;
                font-weight: bold;
                margin: 2.33em 0;
              }
            }
          }
        }
      }
    }

    table {
      border-collapse: separate;
      border-spacing: 2px;
      border-color: gray;
    }

    thead,
    tbody,
    tfoot,
    tr {
      border-color: inherit;
      vertical-align: middle;
    }

    td,
    th {
      padding: 1px;
      vertical-align: inherit;
    }

    th {
      font-weight: bold;
    }

    caption {
      text-align: center;
    }

    ul,
    menu {
      list-style-type: disc;
      margin: 1em 0;
      padding: 0 0 0 40px;
    }

    ol {
      list-style-type: decimal;
      margin: 1em 0;
      padding: 0 0 0 40px;
    }

    ul,
    ol {
      ul {
        list-style-type: circle;
      }

      ul,
      ol {
        ul {
          list-style-type: square;
        }
      }
    }

    dd {
      margin: 0 0 0 40px;
    }

    dl {
      margin: 1em 0;
    }

    ul,
    ol,
    menu,
    dl {
      ul,
      ol,
      menu,
      dl {
        margin: 0;
      }
    }

    legend {
      padding: 0 2px;
    }

    b,
    strong {
      font-weight: bold;
    }

    i,
    cite,
    em,
    var,
    address,
    dfn {
      font-style: italic;
      font-weight: inherit;
    }

    abbr[title],
    dfn[title] {
      border-bottom: 0;
      cursor: default;
      font-weight: inherit;
    }

    tt,
    code,
    kbd,
    samp {
      font-family: monospace;
      font-weight: inherit;
    }

    pre {
      font-family: monospace;
      margin: 1em 0;
      white-space: pre;
    }

    mark {
      background-color: yellow;
      color: black;
      font-style: normal;
      font-weight: inherit;
    }

    big {
      font-size: larger;
      font-weight: inherit;
    }

    small {
      font-size: smaller;
      font-weight: inherit;
    }

    sub,
    sup {
      font-weight: inherit;
      line-height: inherit;
      position: static;
    }

    sub {
      font-size: smaller;
      bottom: 0;
      vertical-align: sub;
    }

    sup {
      font-size: smaller;
      top: 0;
      vertical-align: super;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@layer components {
  .focused-input {
    @apply border-primary shadow-[0px_0px_0px_2px_#ffffff,0px_0px_0px_6px_#179be8] outline-none;
  }
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./assets/fonts/GT Eesti/GT-Eesti-Pro-Display-Regular.woff')
    format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./assets/fonts/GT Eesti/GT-Eesti-Pro-Display-Medium.woff')
    format('woff');
  font-weight: bold;
}
