// COLOR PALETTE

$black: black;
$white: white;

$primary-0: #282d61;
$primary-1: #3b4785;
$primary-2: #4a599a;
$primary-3: #6371a8;
$primary-4: #a2abcc;
$primary-5: #e9ebf2;

$secondary-0: #f26233;
$secondary-1: #b34419;
$secondary-2: #d9552b;
$secondary-3: #e75c2f;
$secondary-4: #f3774f;
$secondary-5: #f7ae97;
$secondary-6: #f8eae8;

$monochromatic-0: #212121;
$monochromatic-1: #3e3e3e;
$monochromatic-2: #7a7a7a;
$monochromatic-3: #ababab;
$monochromatic-4: #cacaca;
$monochromatic-5: #e0e0e0;
$monochromatic-6: #ebebeb;
$monochromatic-7: #f6f6f6;

$green-0: #26925e;
$green-1: #2ba664;
$green-2: #31bb67;
$green-3: #36d068;
$green-4: #68dc8e;
$green-5: #9be8b4;
$green-6: #c3f1d2;
$green-7: #d7f6e1;
$green-8: #ebfaf0;
$green-9: #f5fdf7;

$blue-0: #1856ad;
$blue-1: #1b6dc6;
$blue-2: #1f88de;
$blue-3: #3491e1;
$blue-4: #22a5f7;
$blue-5: #59bcf9;
$blue-6: #91d2fb;
$blue-7: #bde4fd;
$blue-8: #d3edfd;
$blue-9: #e9f6fe;
$blue-10: #f4fbff;

// Non Template Colors

$first-extended-0: #161a42;
$first-extended-1: #343a67;
$first-extended-2: #525c8f;
$first-extended-3: #7281b8;
$first-extended-4: #abb5db;
$first-extended-5: #dadff2;
$first-extended-6: #e6e7f5;

$second-extended-0: #437899;
$second-extended-1: #3daaad;
$second-extended-2: #5db8cf;
$second-extended-3: #79c6f2;
$second-extended-4: #89a0e9;
$second-extended-5: #9179e0;
$second-extended-6: #d375a8;
$second-extended-7: #ff7070;
$second-extended-8: #ffa19d;

// SCREEN SIZE
$screen-lg: 1281px; /* hi-res laptops and desktops */
$screen-md: 1025px; /* big landscape tablets, laptops, and desktops */
$screen-sm: 961px; /* tablet, landscape iPad, lo-res laptops ands desktops */
$screen-s: 641px; /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
$screen-xs: 481px; /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
$screen-xxs: 320px; /* smartphones, iPhone, portrait 480x320 phones */

:export {
  black: $black;
  white: $white;

  primary0: $primary-0;
  primary1: $primary-1;
  primary2: $primary-2;
  primary3: $primary-3;
  primary4: $primary-4;
  primary5: $primary-5;

  secondary0: $secondary-0;
  secondary1: $secondary-1;
  secondary2: $secondary-2;
  secondary3: $secondary-3;
  secondary4: $secondary-4;
  secondary5: $secondary-5;
  secondary6: $secondary-6;

  monochromatic0: $monochromatic-0;
  monochromatic1: $monochromatic-1;
  monochromatic2: $monochromatic-2;
  monochromatic3: $monochromatic-3;
  monochromatic4: $monochromatic-4;
  monochromatic5: $monochromatic-5;
  monochromatic6: $monochromatic-6;
  monochromatic7: $monochromatic-7;

  green0: $green-0;
  green1: $green-1;
  green2: $green-2;
  green3: $green-3;
  green4: $green-4;
  green5: $green-5;
  green6: $green-6;
  green7: $green-7;
  green8: $green-8;
  green9: $green-9;

  blue0: $blue-0;
  blue1: $blue-1;
  blue2: $blue-2;
  blue3: $blue-3;
  blue4: $blue-4;
  blue5: $blue-5;
  blue6: $blue-6;
  blue7: $blue-7;
  blue8: $blue-8;
  blue9: $blue-9;
  blue10: $blue-10;

  firstExtended0: $first-extended-0;
  firstExtended1: $first-extended-1;
  firstExtended2: $first-extended-2;
  firstExtended3: $first-extended-3;
  firstExtended4: $first-extended-4;
  firstExtended5: $first-extended-5;
  firstExtended6: $first-extended-6;

  secondExtended0: $second-extended-0;
  secondExtended1: $second-extended-1;
  secondExtended2: $second-extended-2;
  secondExtended3: $second-extended-3;
  secondExtended4: $second-extended-4;
  secondExtended5: $second-extended-5;
  secondExtended6: $second-extended-6;
  secondExtended7: $second-extended-7;
  secondExtended8: $second-extended-8;

  screenLg: $screen-lg;
  screenMd: $screen-md;
  screenSm: $screen-sm;
  screenS: $screen-s;
  screenXs: $screen-xs;
  screenXxs: $screen-xxs;
}
