.isRouteActive {
  color: $primary-0 !important;
}

.sidebar__user {
  background-color: white;
  padding-top: 12px;
  width: 100%;
  grid-row: 2;
  -ms-grid-row: 2;
}

.sidebar__user-circle {
  min-height: 32px;
  max-height: 32px;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  height: 32px;
}
