@import '../../../utils/_colors';

.ImportIcon {
  margin-left: 6px;
  color: $primary0;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    color: $blue0;
  }
}

.VisibleImportIcon {
  margin-left: 6px;
  color: $primary0;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    color: $blue0;
  }
}

.bp4-overlay-open > .bp4-popover2-transition-container {
  z-index: 100;
}
