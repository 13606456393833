.border-left-mono-5 {
  border-left: 1px solid $monochromatic-5;
}

.border-left-mono-6 {
  border-left: 1px solid $monochromatic-6;
}


.border-right-mono-5 {
  border-right: 1px solid $monochromatic-5;
}

.border-right-mono-6 {
  border-right: 1px solid $monochromatic-6;
}

.border-top-mono-5 {
  border-top: 1px solid $monochromatic-5;

}

.borderTopMono5 {
  border-top: 1px solid $monochromatic-5;
}

.borderBottomMono5 {
  border-bottom: 1px solid $monochromatic-5;
}

.border-mono-5 {
  border: 1px solid $monochromatic-5;
}