@import 'vars';
@import 'mixins';
@import 'components';
@import 'widgets';
@import 'survey';

h1 {
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: $monochromatic-0;
  margin: 0;
}

a {
  outline: none !important;
}

* {
  box-sizing: border-box;

  -webkit-hyphens: auto !important;
  -moz-hyphens: auto !important;
  -ms-hyphens: auto !important;
  -o-hyphens: auto !important;
  hyphens: auto !important;

  -webkit-tap-highlight-color: transparent !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

:focus {
  outline: none !important;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.bp4-input-ghost {
  &::placeholder {
    color: $monochromatic-3 !important;
  }
}
