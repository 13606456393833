.popover {
  width: 100%;
  -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
  -moz-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important;
  border: 1px solid $monochromatic-5;
  border-radius: 8px !important;
  background: white;
  font-size: 16px;
  color: $primary-0;

  & .bp4-popover2-content {
    border-radius: 8px;
  }

  margin-top: 10px;
  max-width: 320px;
  min-width: 200px;
}

.bp4-popover2 {
  border-radius: 8px;
  font-size: 16px;

  & .bp4-popover2-content {
    border-radius: 8px;
  }
}

.bp4-menu {
  max-height: 380px;
  overflow-y: auto;
  padding: 0;
  cursor: pointer;
  border-radius: 8px;

  & li {
    margin: 0;
    & .bp4-menu-item {
      padding: 20px;
      cursor: pointer;

      &.bp4-active {
        background-color: rgba(40, 45, 97, 0.1);
        color: $primary-0;
      }

      &:hover {
        background-color: rgba(40, 45, 97, 0.1);
      }
    }
  }
  & .bp4-menu-header {
    padding: 0;

    & .bp4-heading {
      color: $monochromatic-2 !important;
      font-weight: normal;
      font-size: 12px;
      padding: 10px 0 0 20px;
    }
  }
}

.popover__tooltip {
  @include dropShadow(
    0 1px 1px 0 rgba(0, 0, 0, 0.02),
    0 3px 3px 0 rgba(0, 0, 0, 0.02),
    0 9px 9px 0 rgba(0, 0, 0, 0.02),
    0 0 0 1px rgba(0, 0, 0, 0.05)
  );

  & .bp4-popover2-arrow::before {
    box-shadow: unset;
  }

  & .bp4-popover2-content {
    background: white;
    color: unset;
    padding: 0;
  }

  & .bp4-popover-arrow-fill {
    fill: white !important;
  }
}

.popover__selected {
  outline: none !important;

  & span {
    max-width: 200px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.bp4-popover2-target {
  & * {
    outline: none !important;
  }
}

@media only screen and (max-width: $screen-s) {
  .popover {
    max-width: calc(
      100vw - 270px
    ); // available width - sidebar width + 20px of spacing (250 + 20) this way on some devices the popover will show better even with the sidebar expanded
  }
}

.popover-filter {
  max-width: 320px;

  .bp4-text-overflow-ellipsis {
    white-space: normal !important;
  }

  .bp4-popover2-content {
    padding: 0 !important;
  }
}

.bp4-tooltip2 .bp4-popover2-arrow-fill {
  fill: white !important;
}

.bp4-popover2-content {
  color: black !important;
  background-color: white !important;
  border-radius: 8px !important;
}

.bp4-tooltip2 .bp4-popover2-content {
  padding: 5px !important;
}

.bp4-popover2 {
  border-radius: 8px !important;
}

.tooltip__content {
  transform: translateX(-20px) scale(1) !important;
}

.tooltip__content-inner {
  display: flex;
  align-items: flex-start;
  width: 185px;
  padding: 8px;

  font-size: 12px;
}

.tooltip__content-inner__icon {
  margin-right: 5px;
}

.tooltip__content-inner__text {
  overflow-wrap: anywhere;
  word-break: normal;
}

.bp4-overlay * {
  outline: none !important;
}

.bp4-tooltip {
  color: $monochromatic-0;
}
