.searchable-outer-popover {
  box-shadow: none !important;

  & > .bp4-popover2-content {
    padding: 0 !important;
  }
}

.searchable-outer-target {
  max-width: 210px !important;
}

.searchable-dropdown-wrapper {
  .bp4-input {
    width: 210px !important;
    min-height: 46px !important;
    box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05);
    font-size: 16px;
    border-radius: 8px;
    color: $primary-0;

    & input {
      color: $primary-0;
    }

    &::placeholder {
      color: $monochromatic-4;
    }
  }

  .bp4-input-action {
    position: absolute;
    right: 17px !important;
    top: 15.5px;
  }

  .bp4-multi-select {
    position: relative;

    .bp4-input-action {
      top: 13.5px;
    }
  }

  .bp4-popover-open .bp4-input {
    border-radius: 8px 8px 0 0;
  }
}

.searchable-popover {
  max-width: 320px;
  min-width: 210px;
  border-radius: 0 0 8px 8px !important;
  box-shadow: 0 -1px 0 rgba(208, 208, 208, 0.25), 0 2px 8px rgba(0, 0, 0, 0.1),
    0 0 0 rgba(0, 0, 0, 0.05) !important;

  .bp4-menu {
    border-radius: 0 0 8px 8px;

    .bp4-menu-item {
      color: $monochromatic-2;
      border-radius: 0;
    }

    li:last-child {
      .bp4-menu-item {
        border-radius: 0 0 8px 8px;
      }
    }
  }

  .bp4-text-overflow-ellipsis {
    white-space: normal !important;
  }
}

.searchable-dropdown-wrapper-top {
  .bp4-input {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05) !important;
  }

  .bp4-popover-open .bp4-input {
    border-radius: 0 0 8px 8px !important;
  }
}

.searchable-popover-top {
  border-radius: 8px 8px 0 0 !important;
  box-shadow: 0 1px 0 rgba(208, 208, 208, 0.25), 0 -2px 8px rgba(0, 0, 0, 0.1),
    0 0 0 rgba(0, 0, 0, 0.05);

  .bp4-menu {
    border-radius: 8px 8px 0 0 !important;

    .bp4-menu-item {
      border-radius: 0 !important;
    }

    li:first-child {
      .bp4-menu-item {
        border-radius: 8px 8px 0 0 !important;
      }
    }
  }
}

.searchable-input-popover {
  border-radius: 8px !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0.05) !important;
  transform: scale(1) translateY(8px) !important;

  li:first-child {
    .bp4-menu-item {
      border-radius: 8px 8px 0 0 !important;
    }
  }

  .bp4-menu {
    border-radius: 8px;

    .bp4-menu-item {
      color: $monochromatic-2;
    }
  }
}

.searchable-input {
  input {
    border: none;
    border-radius: 8px;
    font-size: 14px;
    background: #f0f1f5;
    box-shadow: none;
    padding-left: 16px;
    width: 100%;
    color: #58595a;
    height: 43px;
    padding-top: 0;
    background: rgba(222, 229, 240, 0.55);

    &::placeholder {
      color: $monochromatic-2;
      font-size: 14px;
    }

    &:focus {
      outline: none !important;
    }
  }
}
