.sidebar {
  @include transition(all 0.2s ease-in-out);
  opacity: 1;

  max-width: 254px;
  height: calc(100vh - 64px - 16px);

  padding: 0 0 32px 32px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & * {
    white-space: nowrap !important;
  }

  &:hover {
    & .edit-entity-btn {
      opacity: 1 !important;
    }
  }
}

.content {
  flex-grow: 1;
  height: calc(100vh - 64px - 16px);
  overflow-y: scroll;
  overflow-x: auto;
}

.container {
  padding: 40px;
}

.isCollapsed {
  min-width: 30px;
  max-width: 30px;
  opacity: 0;
}

.main-section {
  overflow-x: hidden;
}

.widget-pdf:not(:last-child) {
  margin-bottom: 62px;
}
