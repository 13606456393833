.simple_page_filter {
  & > div {
    margin-bottom: 0 -10px;
  }
}

.simple-filter-container {
  display: flex;
  align-items: center;

  flex-wrap: wrap;

  & > div {
    margin-top: 5px;
    margin-left: 20px;
    flex-wrap: wrap;
  }
}

.simple-filter-title {
  font-variant: normal !important;
  -webkit-font-variant: normal !important;
  font-variant-ligatures: none !important;
  -webkit-font-variant-ligatures: none !important;

  //word-break: break-all !important;
}

.freetext-filter {
  width: 200px !important;

  & .bp4-input {
    min-height: 44px !important;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: none;
    border: solid 1px $monochromatic-4;
    color: $primary-0;

    &::placeholder {
      color: $monochromatic-3;
    }

    &:hover {
      border-color: $monochromatic-3;
      &::placeholder {
        color: $monochromatic-2;
      }
    }
    &:focus {
      border-color: $primary-0;
      color: $monochromatic-0;
      &::placeholder {
        color: $monochromatic-2;
      }
    }
  }

  & .bp4-input-action {
    top: 1px;
  }

  &.has-value {
    & .bp4-input {
      border-color: $primary-0;
    }
  }

  & .bp4-button {
    margin-top: 9px;
    padding: 0;
    border-radius: 8px;

    &:hover {
      background-color: rgba(40, 45, 97, 0.1) !important;
    }

    & svg {
      width: 12px;
      height: 12px;
      color: $primary-0;
    }
  }
}

.bp4-tag {
  background-color: rgba(40, 45, 97, 0.1);
  padding: 5px 8px;
  color: $primary-0;
  font-size: 16px;
}

.lock-tooltip {
  max-width: 169px;
  padding: 10px;
  font-size: 12px;
  hyphens: manual !important;
  text-align: center;
}
