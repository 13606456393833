.simple_page_filter {
  & > div {
    margin-bottom: 0 -10px;

    & > div {
      margin-bottom: 10px;
    }
  }
}

.page-heading {
  &:hover {
    & .save-entity {
      opacity: 1 !important;
    }
    & .edit-entity-btn {
      opacity: 1 !important;
    }
  }
}
