@import '../utils/colors';

:focus {
  outline: none !important;
}

label.bp4-control.bp4-checkbox {
  input:checked ~ .bp4-control-indicator {
    &::before {
      background-image: url('../assets/icons/check.svg');
    }
  }
}

.bp4-toast {
  border-radius: 8px;

  &.bp4-intent-primary {
    background-color: $primary0;
  }

  &.bp4-intent-danger {
    background-color: $secondary0;
  }
}

.bp4-toast-container {
  z-index: 400;
}

.skeleton-loading {
  background: $monochromatic7
    linear-gradient(
      100deg,
      rgba(255, 255, 255, 0) 40%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0) 60%
    );
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
  border-radius: 8px;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}

us-button {
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  transform: translateY(-200px);
  z-index: 2147483647;
}

.nav-actions {
  display: flex;

  & > *:not(:first-child) {
    margin-left: 25px;
  }
}

.bp4-suggest-popover {
  .bp4-popover2-content {
    padding: 0 !important;
  }
}

.custom-popover-content {
  .bp4-popover2-content {
    display: contents;
  }
}
