.line-height-normal {
  line-height: normal;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-26 {
  font-size: 26px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-44 {
  font-size: 44px !important;
}

.font-48 {
  font-size: 48px !important;
}

.font-w-400 {
  font-weight: 400 !important;
}

.font-w-500 {
  font-family: 'GT-Eesti-Pro-Display-medium', sans-serif !important;
  font-weight: 500 !important;
}

.font-w-600 {
  font-weight: 600 !important;
}

.font-w-300 {
  font-weight: 300 !important;
}

.font-w-bold {
  font-weight: bold;
}

.font-s-italic {
  font-style: italic;
}

.font-f-light {
  font-family: 'GT-Eesti-Pro-Display', sans-serif;
  font-weight: lighter;
}

.font-f-display-reg {
  font-family: 'GT-Eesti-Pro-Display', sans-serif;
}
