.p-divider__default {
  width: 100%;

  height: 1px;
  background-color: $monochromatic-6;
}

.p-divider__vertical {
  height: 100%;
  width: 1px;
  background-color: $monochromatic-5;
}
