.chart-container {
  height: 300px;
}

.bar-container {
  & > div > svg {
    overflow: visible;
  }
}

.p-crosshair {
  z-index: 999;
  & .rv-crosshair__line {
    background: $monochromatic-3 !important;
  }
}

.p-crosshair__div {
  width: 270px;
  border-radius: 4px;
  background-color: $white;
  @include dropShadow(
    0 1px 1px 0 rgba(0, 0, 0, 0.02),
    0 3px 3px 0 rgba(0, 0, 0, 0.02),
    0 9px 9px 0 rgba(0, 0, 0, 0.02),
    0 0 0 1px rgba(0, 0, 0, 0.05)
  );
}

.rv-hint {
  @include transition(all 0.2s ease-in-out);
  background-color: $white;
  margin-right: 10px;
  padding: 16px;
  @include dropShadow(0px 2px 8px 0px rgba(0, 0, 0, 0.1));
  border: 1px solid $monochromatic-5;
  border-radius: 8px;
  width: 270px;
  z-index: 100;
}

@media only screen and (max-width: $screen-s) {
  .rv-hint {
    width: auto;
    min-width: 100px;
  }

  .p-crosshair__div {
    width: auto;
    min-width: 100px;
  }

  .cross-over-content {
    & .label {
      display: none;
    }
  }
}
