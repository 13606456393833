@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/popover2/lib/css/blueprint-popover2.css';

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('../assets/fonts/GT Eesti/GT-Eesti-Pro-Display-Regular.woff')
    format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display-medium';
  src: url('../assets/fonts/GT Eesti/GT-Eesti-Pro-Display-Medium.woff')
    format('woff');
  font-weight: bold;
}
/* did this one ever worked? */
@font-face {
  font-family: 'GT-Eesti-Pro-Display-light';
  src: url('../assets/fonts/GT Eesti/GT-Eesti-Pro-Display-Light-Italic.otf')
    format('otf');
}

body {
  font-family: 'GT-Eesti-Pro-Display', sans-serif;
  font-feature-settings: 'kern', 'liga', 'pnum', 'ss01';
}

a:hover {
  text-decoration: none;
}

.bp4-select-popover .bp4-menu,
.bp4-suggest-popover .bp4-menu {
  max-height: 400px;
  max-width: 300px;
  overflow: auto;
  padding: 0;
}

.bp4-menu {
  max-height: 600px;
  overflow-y: auto;
}

:root {
  --monochromatic3: #ababab;
  --monochromatic4: #cacaca;
  --monochromatic5: #e0e0e0;

  --primary0: #282d61;
  --primary1: #3b4785;
  --primary2: #4a599a;
  --primary3: #6371a8;

  --secondary0: #f26233;
  --secondary1: #b34419;
  --secondary3: #e75c2f;

  --green0: #26925e;
  --green1: #2ba664;
  --green3: #36d068;

  --orange0: #a66321;
  --orange1: #bf7326;
  --orange3: #f29d49;

  --error: #f26233;
}

.bp4-input-group.bp4-intent-danger .bp4-input {
  box-shadow: 0 0 0 0 rgb(219 55 55 / 0%), 0 0 0 0 rgb(219 55 55 / 0%),
    inset 0 0 0 1px var(--error), inset 0 0 0 1px rgb(16 22 26 / 15%),
    inset 0 1px 1px rgb(16 22 26 / 20%);
}

.bp4-input-group.bp4-intent-danger .bp4-input:focus {
  box-shadow: 0 0 0 0 rgb(219 55 55 / 0%), 0 0 0 0 rgb(219 55 55 / 0%),
    inset 0 0 0 1px var(--error), inset 0 0 0 1px rgb(16 22 26 / 15%),
    inset 0 1px 1px rgb(16 22 26 / 20%);
}

.bp4-form-group.bp4-intent-danger .bp4-form-helper-text {
  color: var(--error);
}

.bp4-button {
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  background-image: none;
  background-color: var(--monochromatic4);
}

.bp4-button:not([class*='bp4-intent-']) {
  box-shadow: none;
  background-image: none;
  background-color: var(--monochromatic4);
}
.bp4-button:not([class*='bp4-intent-']):hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: var(--monochromatic3);
}
.bp4-button:not([class*='bp4-intent-']):active {
  box-shadow: none;
  background-color: var(--monochromatic5);
}

.bp4-button.bp4-intent-danger {
  background-color: var(--secondary0);
  box-shadow: none;
  background-image: none;
}
.bp4-button.bp4-intent-danger:hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: var(--secondary1);
}
.bp4-button.bp4-intent-danger:active {
  box-shadow: none;
  background-color: var(--secondary3);
}

.bp4-button.bp4-intent-primary {
  background-color: var(--primary0);
  box-shadow: none;
  background-image: none;
}
.bp4-button.bp4-intent-primary:hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: var(--primary1);
}
.bp4-button.bp4-intent-primary:active {
  box-shadow: none;
  background-color: var(--primary3);
}

.bp4-button.bp4-intent-success {
  background-color: var(--green0);
  box-shadow: none;
  background-image: none;
}
.bp4-button.bp4-intent-success:hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: var(--green1);
}
.bp4-button.bp4-intent-success:active {
  box-shadow: none;
  background-color: var(--green3);
}

.bp4-button.bp4-intent-warning {
  background-color: var(--orange0);
  box-shadow: none;
  background-image: none;
}
.bp4-button.bp4-intent-warning:hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: var(--orange1);
}
.bp4-button.bp4-intent-warning:active {
  box-shadow: none;
  background-color: var(--orange3);
}

body > a.uf-button-main.uf-visible {
  position: absolute;
  left: 20px;
  right: initial;
}
