.p-table {
  @include basic-table();

  white-space: pre-line !important;

  & .table_cell > .tag {
    min-width: 70px;
  }

  tbody tr:first-child td {
    box-shadow: none !important;
  }
}

.p-tr__expandable {
  border-bottom: 1px solid $monochromatic-5;

  td {
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: $monochromatic-7 !important;
  }

  & {
    td:first-child {
      padding-left: 40px;
    }
  }
}

.table {
  overflow-x: auto;
  height: 100%;
  position: relative;

  & .header {
    width: 100%;
    background: white;
    position: fixed;
  }
}
