.sidebar__links__container {
  display: flex;
  flex-direction: column;

  & .sidebar__link {
    margin-left: 10px;
    font-size: 12px;
    line-height: 1.71;
    text-decoration: none !important;
    color: $monochromatic-3;
  }
}

.isRouteActive {
  color: $primary-0 !important;
}
