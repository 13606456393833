.bp4-popover {
  border-radius: 3px;
}

.bp4-menu {
  max-height: 380px;
  overflow-y: auto;
  padding: 0;
  cursor: pointer;
  border-radius: 8px;

  & li {
    margin: 0;
    & .bp4-menu-item {
      padding: 20px;
      cursor: pointer;
    }
  }
  & .bp4-menu-header {
    padding: 0;

    & .bp4-heading {
      color: $monochromatic-2 !important;
      font-weight: normal;
      font-size: 12px;
      padding: 10px 0 0 20px;
    }
  }
}

.popover__selected {
  & span {
    max-width: 190px !important;
  }
}

.bp4-popover2-target {
  & * {
    outline: none !important;
  }
}

.row-drag-handle {
  .bp4-popover2-content {
    padding: 0 !important;
    width: 210px !important;

    .bp4-menu-item {
      font-size: 16px;
      color: $monochromatic-2;

      &:hover {
        color: $monochromatic-0;
      }
    }
  }
}

.popover-gray-menu {
  .bp4-popover2-content {
    .bp4-menu-item {
      color: $monochromatic-2;

      &:hover {
        color: $monochromatic-0;
      }
    }
  }
}
