label.bp4-control.bp4-radio {
  color: $monochromatic-2;

  &.checked {
    color: $primary-0;
  }

  span.bp4-control-indicator {
    box-sizing: content-box;
    border: 2px solid $monochromatic-2;
    outline: none !important;
    background-color: $primary-5 !important;
    font-size: 16px;
    box-shadow: none;

    &::before {
      width: 16px;
      height: 16px;
    }
  }

  input:checked ~ .bp4-control-indicator {
    border: 2px solid $primary-0;

    &::before {
      background-image: radial-gradient(
        $primary-0,
        $primary-0 35%,
        transparent 42%
      );
    }
  }
}
