@import 'tab_row';

.widget {
  &:not(:last-child) {
    margin-bottom: 60px;
  }

  &:hover {
    & .widget-settings-btn {
      opacity: 1;
    }
  }
}
