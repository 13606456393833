@import 'vars';

.custom-sv-ranking-item__index.custom-sv-ranking-item__index {
  background: $primary-4;
}

.custom-sv-ranking-item__icon.custom-sv-ranking-item__icon {
  fill: $primary-4;
}

.gt-font {
  font-family: 'GT-Eesti-Pro-Display', sans-serif;

  .sv-title {
    font-family: 'GT-Eesti-Pro-Display', sans-serif;
  }
}

.sv-body__page,
.sv-body__footer {
  margin-left: 0;
  margin-right: 0;
}

.sv-root-modern {
  // buttons
  .sv-btn {
    padding: 16px 24px;
    border-radius: 8px;

    &.sv-footer__prev-btn,
    &.sv-matrixdynamic__add-btn,
    &.sv-matrixdynamic__remove-btn,
    &.sv-paneldynamic__add-btn,
    &.sv-paneldynamic__remove-btn,
    &.sv-footer__edit-btn {
      background: transparent;
      color: $primary-0;
      border: 2px solid $primary-0;
      padding: 14px 22px;
    }

    &.sv-matrixdynamic__remove-btn,
    &.sv-paneldynamic__remove-btn {
      color: $secondary-0;
      border-color: $secondary-0;
    }

    &.sv-footer__edit-btn {
      padding: 8px 20px;

      & > span {
        line-height: 20px;
      }
    }
  }

  // text
  .sv-text,
  .sv-comment {
    height: auto;
    padding: 13px 0 13px 20px;
    background-color: white !important;
    border: 1px solid $monochromatic-4;
    border-radius: 8px;
    font-size: 14px;
    color: $primary-0 !important;

    &:disabled {
      color: $monochromatic-3 !important;
    }

    &:hover:not(:disabled) {
      border-color: $monochromatic-2;
    }

    &:focus {
      border-color: $primary-0 !important;
    }
  }

  .sv-question--answered .sv-text:not(:disabled),
  .sv-question--answered .sv-comment:not(:disabled) {
    border-color: $primary-0;
  }

  .sv-comment {
    height: auto;
  }

  // dropdown
  .sv-dropdown {
    height: auto;
    padding: 13px 21px 13px 16px;
    background-color: white !important;
    border: 1px solid $monochromatic-4 !important;
    border-radius: 8px;
    font-size: 14px;
    color: $monochromatic-3 !important;
    background-image: url('../icons/chevron-down-disabled.svg');
    background-size: 12px 6px;

    &:hover {
      border-color: $monochromatic-2 !important;
    }
  }

  .sv-question--answered .sv-dropdown {
    border-color: $primary-0 !important;
    color: $primary-0 !important;
    background-image: url('../icons/chevron-down-primary.svg');
  }

  .sv-question--disabled .sv-dropdown {
    color: $monochromatic-3 !important;
    background-image: url('../icons/chevron-down-disabled.svg');
    border: 1px solid $monochromatic-4 !important;
    cursor: default;
  }

  //  radio
  .sv-radio__svg {
    width: 18px;
    height: 18px;
    border: 2px solid $monochromatic-2 !important;
  }

  .sv-radio--checked use {
    fill: $primary-0;
  }
  .sv-radio--checked:not(.sv-radio--disabled) .sv-radio__svg {
    border-color: $primary-0 !important;
  }

  .sv-radio__decorator {
    border: none !important;
  }

  .sv-selectbase__decorator.sv-item__decorator {
    position: absolute;
    left: -35px;
  }

  .sv-selectbase__label {
    margin-left: 35px;
  }

  .sv-radio .sv-item__control-label {
    top: 5.8px;
    color: $monochromatic-2;
  }

  .sv-radio--checked .sv-item__control-label {
    color: $primary-0;
  }

  .sv-radio--disabled * {
    color: $monochromatic-3 !important;
    border-color: $monochromatic-3 !important;
  }

  .sv-radio--disabled.sv-radio--checked * {
    fill: $monochromatic-3 !important;
  }

  //switch
  .sv-boolean {
    line-height: unset;
  }

  .sv-boolean input:focus ~ .sv-boolean__switch {
    outline: none;
  }

  .sv-boolean__switch {
    height: 19px;
    width: 38px;
    padding: 2.05px 3px;
    margin: 0 8px;

    .sv-boolean__slider {
      height: 14px;
      width: 14px;
    }
  }

  .sv-boolean--indeterminate .sv-boolean__slider {
    margin-left: calc(50% - 7.5px);
  }

  .sv-boolean--checked .sv-boolean__slider {
    margin-left: calc(100% - 14px);
  }

  // checkbox
  .sv-checkbox__decorator {
    height: 18px;
    width: 18px;
    border-radius: 4px;
    left: -30px !important;

    .sv-checkbox__svg {
      height: 18px;
      width: 18px;
      border: 2px solid $monochromatic-2;
      border-radius: 4px;
    }
  }

  .sv-checkbox--allowhover:hover .sv-checkbox__svg {
    fill: white !important;
    background-color: white !important;
  }

  .sv-item__control-label {
    color: $monochromatic-2;
  }

  .sv-checkbox--checked {
    .sv-checkbox__svg {
      fill: $primary-0 !important;
      background-color: $primary-5 !important;
      border-color: $primary-0;
    }

    .sv-item__control-label {
      color: $primary-0;
    }
  }

  .sv-checkbox {
    .sv-item__control-label {
      top: 1.5px;
    }

    .sv-selectbase__label {
      margin-left: 30px !important;
    }
  }

  .sv-checkbox--disabled {
    .sv-string-viewer {
      color: $monochromatic-3;
    }
  }

  .sv-checkbox--disabled.sv-checkbox--checked {
    .sv-checkbox__svg {
      fill: $monochromatic-3 !important;
      background-color: transparent !important;
    }
  }

  //bg
  .sv-table {
    background-color: $form-bg;
  }

  //  text
  .sv-container-modern {
    font-size: 14px;
  }

  h2 {
    font-size: 26px;
    color: $primary-0;
  }

  .sv-question__title--answer {
    background-color: transparent !important;
  }

  .sv-title,
  .sv-question__required-text {
    font-size: 20px;
    font-weight: 400;
  }

  .sv-question__required-text {
    color: $secondary-0;
  }

  .sv-description.sv-question__description {
    color: $monochromatic-2;
  }

  .sv-table__cell--header {
    font-family: 'GT-Eesti-Pro-Display', sans-serif;
  }

  .sv-table__cell.sv-table__cell--header {
    font-size: 16px;
    font-weight: 400;
  }

  .sv-table__cell > .sv-string-viewer {
    font-size: 16px;
  }

  .sv-table__cell {
    vertical-align: middle;
  }
}

.sv-page__title + .sv-page__description {
  margin-top: -1em;
}
