@mixin dropShadow($params...) {
  -webkit-box-shadow: $params;
  -moz-box-shadow: $params;
  box-shadow: $params;
}

@mixin transition($transition...) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
}

@mixin basic-table(
  $td-padding: 20px 20px,
  $td-summary-padding: 30px 20px 30px 20px,
  //$tr-box-shadow: inset 0 -1px 0 0 #e0e0e0
) {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  tr {
    //box-shadow: $tr-box-shadow;
    border-bottom: 1px solid #e0e0e0;
  }

  td {
    word-break: break-word;
  }

  th {
    color: $monochromatic-3 !important;
  }

  th,
  td {
    font-weight: normal;
    vertical-align: middle;
  }

  &:not(.dense) {
    th,
    td {
      padding: $td-padding !important;

      span > span {
        line-height: 21px;
      }
    }
  }

  .collapse-title {
  }

  th,
  td:not(:first-child) {
    text-align: center;
    /* enable tabular numerals */
    font-feature-settings: 'tnum';
  }

  .bg-white {
    background: white !important;
  }

  .total-row {
    box-shadow: none !important;

    td {
      vertical-align: top;
      padding: $td-summary-padding;
    }

    & .subtitle {
      font-weight: lighter;
    }
  }
}

@mixin fixed-shape($size, $radius) {
  min-width: $size;
  min-height: $size;
  height: $size;
  width: $size;
  border-radius: $radius;
}
