.isRouteActive {
  color: $primary-0 !important;
}

.colorWhite {
  color: $white;
}
.colorGreen-0 {
  color: $green-0;
}

.colorSecondary-0 {
  color: $secondary-0;
}

.colorMonochromatic0 {
  color: $monochromatic-0;
}

.colorMonochromatic1 {
  color: $monochromatic-1;
}

.colorMonochromatic2 {
  color: $monochromatic-2;
}

.colorMonochromatic3 {
  color: $monochromatic-3;
}

.colorMonochromatic4 {
  color: $monochromatic-4;
}
.colorMonochromatic5 {
  color: $monochromatic-5;
}

.colorPrimary-0 {
  color: $primary-0 !important;
}

.colorPrimary-1 {
  color: $primary-1;
}

.colorBlue-0 {
  color: $blue-0;
}

.colorBlue-4 {
  color: $blue-4;
}

.colorSecondary-4 {
  color: $secondary-4;
}

.backgroundSecondary-0 {
  background-color: $secondary-0;
}

.backgroundSecondary-5 {
  background-color: $secondary-5;
}

.backgroundGreen-5 {
  background-color: $green-5;
}

.backgroundMonochromatic-0 {
  background-color: $monochromatic-0;
}

.backgroundMonochromatic-3 {
  background-color: $monochromatic-3;
}

.backgroundMonochromatic-4 {
  background-color: $monochromatic-4;
}

.backgroundMonochromatic-6 {
  background-color: $monochromatic-6;
}

.backgroundPrimary-0 {
  background-color: $primary-0;
}

.backgroundPrimary-3 {
  background-color: $primary-3;
}

.backgroundPrimary-5 {
  background-color: $primary-5;
}

.backgroundBlue-0 {
  background-color: $blue-0;
}

.backgroundBlue-1 {
  background-color: $blue-1;
}

.backgroundBlue-2 {
  background-color: $blue-2;
}

.backgroundBlue-3 {
  background-color: $blue-3;
}

.backgroundBlue-4 {
  background-color: $blue-4;
}

.backgroundBlue-5 {
  background-color: $blue-5;
}

.backgroundBlue-6 {
  background-color: $blue-6;
}

.backgroundBlue-7 {
  background-color: $blue-7;
}

.backgroundSecondExtended-1 {
  background-color: $second-extended-1;
}

.isDisabledOpacity05 {
  opacity: 0.5 !important;
}

.hasHiddenHover {
  .isHiddenTarget {
    opacity: 0;
  }

  &:hover {
    .isHiddenTarget {
      opacity: 1;
    }
  }
}
