.u-align-center-y {
  display: flex;
  align-items: center;
}

.u-display-inline-block {
  display: inline-block;
}

.u-white-space__no-wrap {
  white-space: nowrap;
}

.u-position-fixed {
  position: fixed;
}

.u-position-relative {
  position: relative;
}

.u-flex-wrap {
  flex-wrap: wrap;
}

.u-position-absolute {
  position: absolute;
}

.u-display-space-between {
  display: flex;
  justify-content: space-between;
}

.u-align-flex-end {
  align-items: flex-end;
}

.u-display__inline {
  display: inline-block;
}

.u-display-flex {
  display: flex;
}

.u-align-first-baselint {
  align-items: first baseline;
}
.u-justify-flex-end {
  justify-content: flex-end;
}

.u-display-block {
  display: block;
}

.u-display-none {
  display: none;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-cursor-default {
  cursor: default;
}

.u-cursor-disabled {
  cursor: not-allowed;
}

.u-margin-0 {
  margin: 0;
}

.u-margin-20 {
  margin: 20px;
}

.u-margin-top-5 {
  margin-top: 5px;
}

.u-margin-top-0 {
  margin-top: 0;
}

.u-margin-top-10 {
  margin-top: 10px;
}

.u-margin-top-20 {
  margin-top: 20px;
}

.u-margin-top-30 {
  margin-top: 30px;
}

.u-margin-top-40 {
  margin-top: 40px;
}

.u-margin-top-50 {
  margin-top: 50px;
}

.u-margin-top-60 {
  margin-top: 60px;
}

.u-margin-bottom-0 {
  margin-bottom: 0;
}

.u-margin-bottom-10 {
  margin-bottom: 10px;
}

.u-margin-bottom-16 {
  margin-bottom: 16px;
}

.u-margin-bottom-20 {
  margin-bottom: 20px;
}

.u-margin-bottom-24 {
  margin-bottom: 24px;
}

.u-margin-bottom-30 {
  margin-bottom: 30px;
}

.u-margin-bottom-32 {
  margin-bottom: 32px;
}

.u-margin-bottom-40 {
  margin-bottom: 40px;
}

.u-margin-bottom-50 {
  margin-bottom: 50px;
}

.u-margin-bottom-60 {
  margin-bottom: 60px;
}

.u-margin-left-0 {
  margin-left: 0;
}

.u-margin-left-5 {
  margin-left: 5px;
}

.u-margin-left-10 {
  margin-left: 10px;
}

.u-margin-left-20 {
  margin-left: 20px;
}

.u-margin-left-30 {
  margin-left: 30px;
}

.u-margin-right-0 {
  margin-right: 0;
}

.u-margin-right-5 {
  margin-right: 5px;
}

.u-margin-right-10 {
  margin-right: 10px;
}

.u-margin-right-20 {
  margin-right: 20px;
}

.u-margin-right-24 {
  margin-right: 24px;
}

.u-margin-right-30 {
  margin-right: 30px;
}

.u-margin-right-40 {
  margin-right: 40px;
}

.u-margin-right-48 {
  margin-right: 48px;
}

.u-margin-right-60 {
  margin-right: 60px;
}

.u-padding-0 {
  padding: 0;
}

.u-padding-60 {
  padding: 60px;
}

.u-padding-5 {
  padding: 5px;
}

.u-padding-10 {
  padding: 10px;
}

.u-padding-y-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.u-padding-15 {
  padding: 15px;
}

.u-padding-x-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.u-padding-20 {
  padding: 20px;
}

.u-padding-100 {
  padding: 100px;
}

.u-float-right {
  float: right;
}

.u-padding-left-0 {
  padding-left: 0;
}

.u-padding-left-10 {
  padding-left: 10px;
}

.u-padding-left-20 {
  padding-left: 20px;
}

.u-padding-left-40 {
  padding-left: 40px;
}

.u-padding-left-50 {
  padding-left: 50px;
}

.u-padding-left-100 {
  padding-left: 100px;
}

.u-padding-right-0 {
  padding-right: 0;
}

.u-padding-right-10 {
  padding-right: 10px;
}

.u-padding-right-20 {
  padding-right: 20px;
}

.u-padding-right-40 {
  padding-right: 40px;
}

.u-padding-right-50 {
  padding-right: 50px;
}

.u-padding-right-100 {
  padding-right: 100px;
}

.u-padding-bottom-0 {
  padding-bottom: 0;
}

.u-padding-bottom-10 {
  padding-bottom: 10px;
}

.u-padding-bottom-20 {
  padding-bottom: 20px;
}

.u-padding-bottom-30 {
  padding-bottom: 30px;
}

.u-padding-bottom-40 {
  padding-bottom: 40px;
}

.u-padding-bottom-50 {
  padding-bottom: 50px;
}

.u-padding-top-0 {
  padding-top: 0;
}

.u-padding-top-10 {
  padding-top: 10px;
}

.u-padding-top-20 {
  padding-top: 20px;
}

.u-padding-top-30 {
  padding-top: 30px;
}
.u-padding-top-40 {
  padding-top: 40px;
}

.u-padding-top-80 {
  padding-top: 80px;
}

.u-vertical-align-top {
  vertical-align: top;
}

.u-vertical-align-middle {
  vertical-align: middle;
}

.u-justify-space-between {
  justify-content: space-between;
}

.u-justify-space-around {
  justify-content: space-around;
}

.u-justify-space-evenly {
  justify-content: space-evenly;
}

.u-justify-center {
  justify-content: center;
}

.u-pull-right {
  float: right;
}

.u-text-align-left {
  text-align: left !important;
}

.u-text-align-right {
  text-align: right !important;
}

.u-text-align-center {
  text-align: center !important;
}

.u-pull-left {
  float: left;
}

.u-width-100 {
  width: 100%;
}

.u-width-100px {
  width: 100px;
}

.u-width-50 {
  width: 50%;
}

.u-height-100 {
  height: 100%;
}

.u-height-100vh {
  height: 100vh;
}

.u-width-100vw {
  width: 100vw;
}

.u-width-0 {
  width: 0 !important;
}

.u-width-auto {
  width: auto !important;
}

.border-radius-50 {
  border-radius: 50%;
}

.border-radius-2 {
  border-radius: 2px;
}

.u-word-wrap-all {
  word-wrap: break-word;
}

.u-center {
  align-self: center;
  margin: auto;
}

.u-bottom-center {
  align-self: center;
  margin-top: auto;
}

.u-absolute-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.u-left-10 {
  left: 10px;
}

.u-top-10 {
  top: 10px;
}

.u-top-0 {
  top: 0;
}

.u-right-0 {
  right: 0;
}

.u-opacity-0 {
  opacity: 0;
}

.u-opacity-1 {
  opacity: 1;
}

.u-table-fixed {
  table-layout: fixed;
}

.u-overflow-y-scroll {
  overflow-y: scroll;
}

.u-overflow-y-auto {
  overflow-y: auto;
}

.u-overflow-x-auto {
  overflow-x: auto;
}

.u-has-transition {
  @include transition(all 0.3s ease-in-out);
}

.u-white-space__pre-line {
  white-space: pre-line;
}

.u-hyphens-none {
  hyphens: none !important;
}

.u-caret-transparent {
  caret-color: transparent;
}

.u-fit-content {
  width: fit-content;
}
