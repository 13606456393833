.link__alt {
  @include transition(color 0.2 ease-in);
  color: $primary-0;

  &:hover {
    text-decoration: none !important;
    color: $primary-2;
  }
}

.link__mono3 {
  @include transition(color 0.2 ease-in);
  color: $monochromatic-3;

  &:hover {
    text-decoration: none !important;
    color: $primary-3;
  }
}

.bp4-button {
  border-radius: 8px;
}

.bp4-button.bp4-intent-primary {
  background-color: $primary-0;
  box-shadow: none;
  background-image: none;
}
.bp4-button.bp4-intent-primary:hover {
  box-shadow: 0 2px 4px 0 #cacaca;
  background-color: $primary-1;
}
.bp4-button.bp4-intent-primary:active {
  box-shadow: none;
  background-color: $primary-3;
}
